import { ErrorDescription } from './interfaces';

export class ResponseApiResult<TData> {
    public static createSuccessful<TData>(data: TData) {
        return new ResponseApiResult(true, data);
    }

    public static createUnsuccessful<T>(error: ErrorDescription) {
        return new ResponseApiResult<T>(false, undefined, error);
    }

    private _error?: ErrorDescription;
    private _data?: TData;

    private constructor(
        public readonly isSuccessful: boolean,
        data?: TData,
        error?: ErrorDescription
    ) {
        this._data = data;
        this._error = error;
    }

    public get error() {
        if (this.isSuccessful) {
            throw new Error('Cannot access error when result is successful.');
        }

        return this._error;
    }

    public get data() {
        if (!this.isSuccessful) {
            throw new Error('Cannot access data when result is unsuccessful.');
        }

        return this._data;
    }

    public match<T>(
        onSuccess: (data?: TData) => T,
        onError: (error?: ErrorDescription) => T,
    ): T {
        return this.isSuccessful ? onSuccess(this.data) : onError(this.error);
    }
}
