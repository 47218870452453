import { Chat } from 'models/Chat/Chat';

import { CHAT } from './constants';
import { axiosInstance } from '../axiosInstance';
import { requestWrapper } from '../requestWrapper';

export const initiaslChat = {
    id: 0,
    name: 'Chat with AI',
    botName: 'AI doctor',
    botPersonality: 'you are a professional doctor with great experience',
    messages: [],
};

export const ChatAPI = {
    async createChat(name: string) {
        const request = () => axiosInstance.post(`${CHAT}/createchat`, { ...initiaslChat, name });

        return await requestWrapper<Chat>(request, 'Failed to create chat');
    },
    async getChatById(id: number) {
        const request = () => axiosInstance.get(`${CHAT}/getchatbyid?id=${id}`);

        return await requestWrapper<Chat>(request, 'Failed to get chat');
    },
    async getChats() {
        const request = () => axiosInstance.get(`${CHAT}/getmychats`);

        return await requestWrapper<Chat[]>(request, 'Failed to get chats');
    },
    async deleteChat(id: number) {
        const request = () => axiosInstance.delete(`${CHAT}/deletechatbyid?id=${id}`);

        return await requestWrapper<Chat>(request, 'Failed to delete chat');
    },
};
