import { AxiosResponse } from 'axios';

import { processSuccessfulResponseResult, processUnsuccessfulResponseResult } from '../shared/utils/apiResult';

export const requestWrapper = async <T>(
    request: () => Promise<AxiosResponse<T>>,
    errorMessage: string,
  ) => {
    try {
        const response = await request();

        const result = processSuccessfulResponseResult<T>(response);

        return result;
    } catch (error) {
        return processUnsuccessfulResponseResult<T>(error, errorMessage);
    }
  };
