import { confirmable } from 'react-confirm';

import { Dialog } from '../Dialog';

import './ConfirmationDialog.scss';

interface ConfirmationDialog {
    show: boolean;
    confirmationText: string;
    title?: string;
    proceed: () => void;
    cancel: () => void;
}

function ConfirmationDialog({ show, title, confirmationText, proceed, cancel }: ConfirmationDialog) {
    return (
        <Dialog
            isOpen={show}
            title={title ?? ''}
            className='confirmation-dialog'
            onProceed={proceed}
            onCancel={cancel}
            content={<span className='confirmation-dialog__content'>{confirmationText}</span>}
            onDoneLabel='Yes'
            onCancelLabel='No'
        />
    );
}

const EnhancedConfiramtionDialog = confirmable(ConfirmationDialog);

export { EnhancedConfiramtionDialog as ConfirmationDialog };
