import { useState, useCallback } from 'react';
import { useStore } from 'effector-react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Modal } from '../../../shared/components/Modal';
import { TextField } from '../../../shared/components/TextField';
import { ButtonVariant } from '../../../shared/components/Button';
import { LoadingButton } from '../../../shared/components/LoadingButton';
import { createChat, $isCreatingcChat } from '../../../store/chat';

import './NewChat.scss';

export const ChatModal = ({ open, setToggle }: { open: boolean, setToggle: () => void }) => {
    const isCreating = useStore($isCreatingcChat);

    const [title, setTitle] = useState('');

    const handleClose = useCallback(() => {
        setToggle();
        setTitle('');
    }, [setToggle]);

    const createNewChat = useCallback(async () => {
        await createChat(title);
        handleClose();
    }, [title, handleClose]);

    return (
        <Modal isOpen={open} onClose={setToggle}>
            <IconButton
                className='create-chat__close-button'
                onClick={handleClose}
                children={<CloseIcon />}
            />
            <TextField
                className='create-chat__input'
                placeholder='Write chat title'
                onChange={setTitle}
                value={title}
                multiline
            />
            <LoadingButton
                onClick={createNewChat}
                variant={ButtonVariant.Contained}
                loading={isCreating}
            >
                Create new chat
            </LoadingButton>
        </Modal>
    );
};
