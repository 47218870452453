import { User } from 'models/User/User';

import { USER } from './constants';
import { axiosInstance } from '../axiosInstance';
import { requestWrapper } from '../requestWrapper';

export const UserAPI = {
    async getUserInfo() {
        const request = () => axiosInstance.get(USER);

        return await requestWrapper<User>(request, 'Failed to get user info');
    },
};
