import { useCallback } from 'react';
import { useStore } from 'effector-react';
import { IconButton} from '@mui/material';
//import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
//import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
//import PlaylistRemoveOutlinedIcon from '@mui/icons-material/PlaylistRemoveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
//import classNames from 'classnames';

import { AsyncConfirmationDialog } from '../../../shared/components/ConfirmationDialog';
//import { useCloseByOutsideClick } from 'shared/utils/hooks/useCloseByOutsideClick';
import { $activeChatId, deleteChat } from '../../../store/chat';

import './ChatMenu.scss';

export const ChatMenu = () => {
    //const menuSelectRef = useRef<HTMLDivElement>(null);
    const activeChatId = useStore($activeChatId);

    //const [open, setOpen] = useState(false);

    //useCloseByOutsideClick(menuSelectRef, setOpen);

    //const handleOpenMenu = () => setOpen(open => !open);

    const handleDeleteChat = useCallback(async () => {
        const isDeletionConfirmed = await AsyncConfirmationDialog({
            confirmationText: 'Are you sure you want to delete current chat?',
        });

        if (isDeletionConfirmed && activeChatId) {
            await deleteChat(activeChatId);
        }
    }, [activeChatId]);

    // const settings = useMemo(() => ([
    //     { name: 'Search', icon: ManageSearchOutlinedIcon },
    //     { name: 'Clear History', icon: PlaylistRemoveOutlinedIcon },
    //     { name: 'Delete Chat', icon: DeleteOutlineOutlinedIcon, callBack: handleDeleteChat },
    // ]), [handleDeleteChat]);

    return (
        <div className='chat-menu'>
            <IconButton onClick={handleDeleteChat} className='chat-menu__icon'>
                <DeleteOutlineOutlinedIcon />
            </IconButton>
            {/* {<div className={classNames('chat-menu__select', { openMenu: !open })} ref={menuSelectRef}>
                {settings.map((setting) => (
                    <MenuItem 
                        key={setting.name} 
                        className='element'
                        onClick={setting.callBack} 
                    >
                        <setting.icon />
                        <div className='element__name'>{setting.name}</div>
                    </MenuItem>
                ))}
            </div>} */}
        </div>
    );
};
