import { createGate } from 'effector-react';
import { attach, createEffect } from 'effector';
import {
  ApplicationInsights,
  IEventTelemetry,
} from '@microsoft/applicationinsights-web';

import { User } from 'models/User/User';
import { $user } from 'store/user';

type EventParams = {
  name: IEventTelemetry['name'];
  customProperties?: IEventTelemetry['properties']
}

export const ApplicationInsightsGate = createGate<{
  appInsights: ApplicationInsights
}>();

export const $appInsights = ApplicationInsightsGate.state.map(({ appInsights }) => appInsights);

export const trackEventFx = attach({
  source: {
    appInsights: $appInsights,
    user: $user,
  },
  mapParams: (params: EventParams, { appInsights, user }) => ({ ...params, appInsights, user }),
  effect: createEffect(
    (params: EventParams & { appInsights: ApplicationInsights, user: User }) => {
      const { name, customProperties, appInsights, user } = params;

      return new Promise((res, rej) => {
        try {
          appInsights.trackEvent({ name }, {
            ...customProperties,
            userInfo: {
              email: user.primaryEmail,
              name: `${user.firstName} ${user.lastName}`,
            },
          });

          res(true);
        } catch (error) {
          rej(false);
        }
      });
    }),
});

export const startTrackEventFx = attach({
  source: {
    appInsights: $appInsights,
  },
  mapParams: (params: EventParams, { appInsights }) => ({ ...params, appInsights }),
  effect: createEffect(
    (params: EventParams & { appInsights: ApplicationInsights }) => {
      const { name, appInsights } = params;

      return new Promise((res, rej) => {
        try {
          appInsights.startTrackEvent(name);

          res(true);
        } catch (error) {
          rej(false);
        }
      });
    }),
});

export const stopTrackEventFx = attach({
  source: {
    appInsights: $appInsights,
    user: $user,
  },
  mapParams: (params: EventParams, { appInsights, user }) => ({ ...params, appInsights, user }),
  effect: createEffect(
    (params: EventParams & { appInsights: ApplicationInsights, user: User }) => {
      const { name, customProperties, appInsights, user } = params;

      return new Promise((res, rej) => {
        try {
          appInsights.stopTrackEvent(name, {
              ...customProperties,
              userInfo: user.primaryEmail,
          });

          res(true);
        } catch (error) {
          rej(false);
        }
      });
    }),
});
