import { IconButton } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { useToggle } from '../../../shared/utils/hooks/useToggle';
import { ChatModal } from './ChatModal';

import './NewChat.scss';

export const NewChat = () => {
    const [open, setToggle] = useToggle(false);

    return (
        <>
            <IconButton
                className='create-chat__button'
                onClick={setToggle}
                children={<AddOutlinedIcon />}
            />
            <ChatModal
                open={open}
                setToggle={setToggle}
            />
        </>
    );
};
