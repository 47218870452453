import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { requestInterceptor, responseInterceptor } from '@medamerica/okta-react';

import { BASE_URL } from './constants';

interface AxiosRequestConfigCustom extends AxiosRequestConfig {
    canceledTokenExpired?: boolean;
}

export const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(async config => {
    return await requestInterceptor(config);
});

axiosInstance.interceptors.response.use(response => {
    return response;
}, async (error: AxiosError) => {
    const config = error.config as AxiosRequestConfigCustom;

    return await responseInterceptor(config, () => axiosInstance(config), error);
});
