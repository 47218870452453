import { sample } from 'effector';

import { $messages } from '../message/store';
import { $isMobile } from '../main/store';
import { setActiveChatId, getChats } from './events';
import { $activeChatId, $chats } from './store';
import { createChat, deleteChat, getMyChats } from './effects';

sample({
    clock: getChats,
    target: getMyChats,
});

sample({
    clock: getMyChats.doneData,
    target: $chats,
});

sample({
    clock: setActiveChatId,
    target: $activeChatId,
});

sample({
    clock: createChat.doneData,
    source: $chats,
    fn: (chats, chat) => [chat, ...chats],
    target: $chats,
});

sample({
    clock: createChat.doneData,
    fn: chat => chat.id,
    target: $activeChatId,
});

sample({
    clock: [deleteChat.done], //add chatId to response
    target: getChats,
});

sample({
    clock: deleteChat.done,
    fn: () => [],
    target: $messages,
});

sample({
    clock: deleteChat.done,
    fn: () => null,
    target: $activeChatId,
});

sample({
    clock: getMyChats.doneData,
    source: $isMobile,
    filter: (isMobile) => !isMobile,
    fn: (_, chats) => chats[0]?.id,
    target: setActiveChatId,
});
