import { forwardRef } from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import cn from 'classnames';

import { ButtonColor } from './ButtonColor';
import { ButtonVariant } from './ButtonVariant';
import { ButtonSize } from './ButtonSize';

import './Button.scss';

export interface ButtonProps extends Omit<MuiButtonProps, 'size' | 'variant' | 'color' | 'type'> {
    size?: ButtonSize;
    variant?: ButtonVariant;
    color?: ButtonColor;
    submit?: boolean;
    textTransform?: boolean;
    buttonClassName?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
    size = ButtonSize.Medium,
    variant,
    color,
    submit = false,
    textTransform,
    buttonClassName,
    ...restProps
}, ref) => {
    return (
        <MuiButton
            {...restProps}
            ref={ref}
            classes={{
                root: cn(
                    'chat-ai-button',
                    { 'chat-ai-button_text-transform': textTransform },
                    buttonClassName,
                ),
                containedPrimary: 'chat-ai-button_contained-primary',
            }}
            size={size}
            variant={variant}
            color={color}
            type={submit ? 'submit' : 'button'}
        />
    );
});
