import { createConfirmation } from 'react-confirm';

import { ConfirmationDialog } from './ConfirmationDialog';

const confirmation = createConfirmation(ConfirmationDialog);

interface AsyncConfirmationDialogOptions {
    title?: string;
    confirmationText: string;
}

export async function AsyncConfirmationDialog(options: AsyncConfirmationDialogOptions) {
    try {
        await confirmation({
            ...options,
        });

        return true;
    } catch {
        return false;
    }
}
