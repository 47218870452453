import { useState, useCallback } from 'react';

export function useToggle(initialState: boolean) {
    const [toggleStatus, setToggleStatus] = useState(initialState);

    const toggle = useCallback(() => {
        setToggleStatus(!toggleStatus);
    }, [toggleStatus]);

    return [toggleStatus, toggle, setToggleStatus] as const;
}
