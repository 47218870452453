import { useEffect } from 'react';

export function useCloseByOutsideClick(
    ref: React.MutableRefObject<HTMLElement | null>,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
) {
    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (ref.current && !event.composedPath().includes(ref.current)) {
                setOpen(false);
            }
        };

        document.body.addEventListener('mousedown', handleOutsideClick);

        return () => document.body.removeEventListener('mousedown', handleOutsideClick);
    }, [ref, setOpen]);
}
