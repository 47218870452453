import { Link } from 'react-router-dom';

import './Logo.scss';

export const Logo = () => {
    return (
        <div className='header__logo' onClick={() => {  }}>
            <Link to='/' >
                <img className='logo' src='/logo_with_tagline.png' />
            </Link>
        </div>
    );
};
