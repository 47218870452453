import { createEffect } from 'effector';

import { UserAPI } from 'api/user/api';
import { showErrorNotification } from 'shared/utils/notification';
import { User } from 'models/User/User';

import { initialUser } from './constants';

export const fetchUser = createEffect(async (): Promise<User> => {
    const result = await UserAPI.getUserInfo();

    return result.match(
        result => result ?? initialUser,
        error => {
            showErrorNotification(error);

            return initialUser;
        },
    );
});

export const $isFetchingUser = fetchUser.pending;
