import { createStore, combine } from 'effector';

import { Chat } from 'models/Chat/Chat';

import { $isLoadingChats } from './effects';

export const $chats = createStore<Chat[]>([]);

export const $activeChatId = createStore<number | null>(null);

export const $chatsState = combine({
    chats: $chats,
    isLoadingChats: $isLoadingChats,
});
