import { memo } from 'react';
import cn from 'classnames';

import { Logo } from '../../shared/components/Logo/Logo';
import { UserMenu } from './UserMenu/UserMenu';
import { ChatMenu } from './ChatMenu/ChatMenu';
import { NewChat } from './NewChat/NewChat';
import { Beta } from './Beta/Beta';

import './Header.scss';

export const Header = memo(() => {
    return (
        <header className='header'>
            <div className={cn('container', 'header__content')}>
                <Logo />
                <div className='header__menu'>
                    <Beta />
                    <NewChat />
                    <ChatMenu />
                    <UserMenu />
                </div>
            </div>
        </header>
    );
});
