import { forwardRef } from 'react';
import MuiLoadingButton, { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab/LoadingButton';
import cn from 'classnames';

import { ButtonColor } from '../Button/ButtonColor';
import { ButtonVariant } from '../Button/ButtonVariant';
import { ButtonSize } from '../Button/ButtonSize';

import '../Button/Button.scss';

export interface LoadingButtonProps extends Omit<MuiLoadingButtonProps, 'size' | 'variant' | 'color' | 'type'> {
    size?: ButtonSize;
    variant?: ButtonVariant;
    color?: ButtonColor;
    component?: string;
    submit?: boolean;
    textTransform?: boolean;
    buttonClassName?: string;
}

export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(({
    size = ButtonSize.Medium,
    variant,
    color,
    submit = false,
    textTransform = false,
    buttonClassName,
    ...restProps
}, ref) => {

    return (
        <MuiLoadingButton
            {...restProps}
            ref={ref}
            classes={{
                root: cn(
                    'chat-ai-button',
                    { 'chat-ai-button_text-transform': textTransform },
                    buttonClassName,
                ),
                containedPrimary: 'chat-ai-button_contained-primary',
            }}
            size={size}
            variant={variant}
            color={color}
            type={submit ? 'submit' : 'button'}
        />
    );
});
