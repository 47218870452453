const link = 'https://vituity.sharepoint.com/:w:/r/sites/enterprisepolicies/_layouts/15/Doc.aspx?sourcedoc=%7B2AAB79F9-CCB3-4A07-A563-8CF5023835EF%7D&file=16.C.%20Information%20Classification%20Matrix.docx&action=default&mobileredirect=true';

const mailTo = 'mailto:compliance@vituity.com';

export const footerText = `This service is designed for internal use only. We are committed to maintaining the highest standards of 
    data security and privacy. By using this service, you agree not to share 
    <a href="${link}" target="_blank">confidential or patient information or store sensitive information</a> in chat history as per 
    our policy. Further, you agree that this service can collect and retain your chat history for service improvement. You agree to 
    use this service in compliance with all applicable policies, laws and regulations governing data privacy, security, and 
    confidentiality. If you suspect any unauthorized misuse, or breach of data, please report it immediately to 
    <a href="${mailTo}">compliance@vituity.com</a>`;
