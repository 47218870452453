import { AxiosResponse } from 'axios';

import { BAD_REQUEST_STATUS_CODE, INTERNAL_SERVER_ERROR_STATUS_CODE, NOT_FOUND_STATUS_CODE } from '../../constants/httpStatusCodes';
import { INTERNAL_SERVER_ERROR_MESSAGE, NOT_FOUND_ERROR_MESSAGE } from '../../constants/httpStatusMessages';
import { ResponseApiResult } from './Result';

export function processSuccessfulResponseResult<ResultDataType>(
    response: AxiosResponse,
): ResponseApiResult<ResultDataType> {
    const result = response.data;

    return ResponseApiResult.createSuccessful(result);
}

export function processUnsuccessfulResponseResult<T>(error: any, errorDescription: string): ResponseApiResult<T> {
    const CANCELED_ERROR_CODE = 'ERR_CANCELED';

    const status = error.response?.status;
    const statusText = error.response?.statusText ?? '';
    let errorMessage = '';

    switch (status) {
        case BAD_REQUEST_STATUS_CODE:
            errorMessage = statusText;
            break;
        case NOT_FOUND_STATUS_CODE:
            errorMessage = NOT_FOUND_ERROR_MESSAGE;
            break;
        case INTERNAL_SERVER_ERROR_STATUS_CODE:
            errorMessage = INTERNAL_SERVER_ERROR_MESSAGE;
            break;
        default:
            errorMessage = error.message;
    }

    return ResponseApiResult.createUnsuccessful<T>({
        message: `${errorDescription}: ${errorMessage}`,
        canceled: error.code === CANCELED_ERROR_CODE,
    });
}
