import { sample } from 'effector';
import { authStores } from '@medamerica/okta-react';

import { fetchUser } from './effects';
import { getUser } from './events';
import { $user } from './store';

sample({
    clock: authStores.$authState,
    filter: authState => !!authState.authChecked && !!authState.authorized,
    target: getUser,
});

sample({
    clock: getUser,
    target: fetchUser,
});

sample({
    clock: fetchUser.doneData,
    target: $user,
});
