import { useMemo, useState, useRef, useCallback } from 'react';
import { useAuthState, useAuthProvider } from '@medamerica/okta-react';
import { IconButton, MenuItem, Avatar } from '@mui/material';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import classNames from 'classnames';

import { useCloseByOutsideClick } from '../../../shared/utils/hooks/useCloseByOutsideClick';

import './UserMenu.scss';

export const UserMenu = () => {
    const { authorized } = useAuthState();
    const authProvider = useAuthProvider();

    const [user, setUser] = useState(false);

    const menuSelectRef = useRef<HTMLDivElement>(null);

    useCloseByOutsideClick(menuSelectRef, setUser);

    //const userInitials = getUserInitials(userInfo);

    const handleOpenMenu = () => setUser(user => !user);

    const handleCloseMenu = useCallback(async () => {
        await authProvider?.onLogOut();
        setUser(false);
    }, [authProvider]);

    const settings = useMemo(() => ([{ name: 'Logout', callBack: handleCloseMenu }]), [handleCloseMenu]);

    return (
        <div className='user'>
            {/* <Avatar>{userInitials}</Avatar> */}
            <IconButton
                onClick={handleOpenMenu}
                children={
                    <Avatar
                        className='user__icon'
                        children={<PermIdentityOutlinedIcon />}
                    />
                }
            />
            {authorized && (
                <div className={classNames('user__menu', { openMenu: !user })} ref={menuSelectRef}>
                    {settings.map((setting) => (
                        <MenuItem key={setting.name} onClick={setting.callBack}>
                            <div>{setting.name}</div>
                        </MenuItem>
                    ))}
                </div>
            )}
        </div>
    );
};
