import { Logo } from '../../shared/components/Logo/Logo';

import './NotFound.scss';

export const NotFound = () => {
    return (
        <div className='not-found'>
            <Logo />
            <div className='not-found__content'>
                <div className='not-found__heading'>Oh no. Something went wrong.</div>
                <div>Page Not Found</div>
            </div>
        </div>
    );
};
