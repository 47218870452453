import { Suspense } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import {
    RequireAuth,
    OktaAuth,
    AuthProvider,
    SignInRoute,
    Authentication,
} from '@medamerica/okta-react';

import { NotFound } from '../../../pages/NotFound/NotFound';
import { Loader } from '../../../shared/components';
import { config } from './authConfig';
import { routes } from './routerConfig';
import { PrivateContainer } from './PrivateContainer/PrivateContainer';

const authentication = new Authentication(config);

export const AppRouter = () => {
    return (
        <AuthProvider
            authProvider={authentication}
            fallback={<Loader />}
        >
            <Router>
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route element={<RequireAuth children={<PrivateContainer />} />}>
                            {routes.map(route => (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={<route.component />}
                                />
                            ))}
                        </Route>
                        <Route path={SignInRoute.OktaSignInCallback} element={<OktaAuth />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Suspense>
            </Router>
        </AuthProvider>
    );
};
