import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';

import { footerText } from '../../shared/constants/footerText';
import { PrivateRoute } from '../../models/Module';

import './Footer.scss';

export const Footer = () => {
    const __html = DOMPurify.sanitize(footerText, { ADD_ATTR: ['target'] });

    return (
        <div className='footer'>
            <div className='container'>
                <div className='footer__content'>
                    <div className='footer__text' dangerouslySetInnerHTML={{ __html }} />
                    <Link to={PrivateRoute.Chat} className='footer__logo'>
                        <img className='logo' src='/logo_with_tagline.png' />
                    </Link>
                </div>
            </div>
        </div>
    );
};
