import { BASE_URL, OKTA_AUTH_URL, OKTA_LOGOUT_URL } from '../../../api/constants';

export const config = {
    logoutRedirectUri: '/',
    loginRedirectUri: '/',
    hasLoginPage: false,
    authType: 'okta',
    urls: {
        api: BASE_URL,
        oktaUrl: OKTA_AUTH_URL,
        oktaLogout: OKTA_LOGOUT_URL,
    }
};
