import { Outlet } from 'react-router-dom';

import { Header } from '../../../../widgets/Header/Header';
import { Footer } from '../../../../widgets/Footer';

import './PrivateContainer.scss';

export const PrivateContainer = () => {
    return (
        <div className='chat-bot'>
            <Header />
            <div className='chat-bot__content'>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
};
