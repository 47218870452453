import { createEffect } from 'effector';

import { Chat } from '../../models/Chat/Chat';
import { ChatAPI, initiaslChat } from '../../api/chat/api';
import { showErrorNotification } from '../../shared/utils/notification';

export const createChat = createEffect(async (name: string): Promise<Chat> => {
    const result = await ChatAPI.createChat(name);

    return result.match(
        result => result ?? initiaslChat,
        error => {
            showErrorNotification(error);

            return initiaslChat;
        },
    );
});

export const $isCreatingcChat = createChat.pending;

export const getChatById = createEffect(async (id: number): Promise<Chat> => {
    const result = await ChatAPI.getChatById(id);

    return result.match(
        result => result ?? initiaslChat,
        error => {
            showErrorNotification(error);

            return initiaslChat;
        },
    );
});

export const $isLoadingChatById = getChatById.pending;

export const getMyChats = createEffect(async (): Promise<Chat[]> => {
    const result = await ChatAPI.getChats();

    return result.match(
        result => result ?? [],
        error => {
            showErrorNotification(error);

            return [];
        },
    );
});

export const $isLoadingChats = getMyChats.pending;

export const deleteChat = createEffect(async (id: number): Promise<boolean> => {
    const result = await ChatAPI.deleteChat(id);

    return result.match(
        () => true,
        error => {
            showErrorNotification(error);

            return false;
        },
    );
});
