import cn from 'classnames';

import './Loader.scss';

interface LoaderProps {
    containerClassName?: string;
}

export const Loader = ({ containerClassName }: LoaderProps) => (
    <div className={cn('loader', containerClassName)} role="progressbar">
        <div className='loader__content'></div>
    </div>
);
