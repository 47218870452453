import { ReactNotifications } from 'react-notifications-component';
import { ErrorBoundary } from 'react-error-boundary';
import { useGate } from 'effector-react';

import { ErrorFallback } from 'shared/components/ErrorFallback';
import { ApplicationInsightsGate } from 'store/appInsights/effects';

import { AppRouter, ThemeProvider } from './providers';
import { appInsights } from './providers/AppInsights';

import 'react-notifications-component/dist/theme.css';
import './App.scss';

export const App = () => {
    useGate(ApplicationInsightsGate, { appInsights });

    return (
        <>
            <ReactNotifications />
            <ThemeProvider>
                <ErrorBoundary
                    onError={error => appInsights.trackException({ severityLevel: 3, exception: error })}
                    FallbackComponent={ErrorFallback}
                >
                    <AppRouter />
                </ErrorBoundary>
            </ThemeProvider>
        </>
    );
};
