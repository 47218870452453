import { IConfiguration, IConfig } from '@microsoft/applicationinsights-web';

type InsightsConfig = IConfiguration & IConfig;

export const insightsConfig: InsightsConfig = {
    connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
    disableAjaxTracking: true,
    disableFetchTracking: true,
    enableAutoRouteTracking: false,
    autoTrackPageVisitTime: false,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: false,
    enableResponseHeaderTracking: false,
};
