import {
    Dialog as MuiDialog,
    DialogProps as MuiDialogProps,
} from '@mui/material';
import cn from 'classnames';

import './Modal.scss';

export interface ModalProps extends Omit<MuiDialogProps, 'open'> {
    isOpen: boolean;
    closeModal?: (event: React.MouseEvent<SVGSVGElement>) => void;
}

export const Modal = ({
    isOpen,
    PaperProps,
    closeModal,
    ...restProps
}: ModalProps) => {
    return (
        <MuiDialog
            {...restProps}
            open={isOpen}
            onClose={closeModal}
            PaperProps={{
                ...PaperProps,
                className: cn('chat-ai-modal-paper', PaperProps?.className),
            }}
            classes={{
                root: 'chat-ai-modal-root',
            }}
        />
    );
};
