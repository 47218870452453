import { Logo } from '../Logo/Logo';

import './ErrorFallback.scss';

type ErrorFallbackProps = {
    error: Error;
};

export const ErrorFallback = ({ error }: ErrorFallbackProps) => {
    const { host } = window.location;

    return (
        <div className='error-fallback'>
            <Logo />
            <div className='error-fallback__content'>
                <div className='error-fallback__heading'>Oh no. Something went wrong.</div>
                <div>Please, contact the administrator.</div>
                {host === 'localhost:3000' && <div>{error.message}</div>}
            </div>
        </div>
    );
};
