import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { insightsConfig } from './config';

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
    config: {
        ...insightsConfig,
        extensions: [reactPlugin],
    },
});

appInsights.loadAppInsights();

export { reactPlugin, appInsights };
